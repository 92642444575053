import "styles/front.scss"
import '@glidejs/glide/dist/css/glide.core.min'
import '@glidejs/glide/dist/css/glide.theme.min.css'
import Glide from '@glidejs/glide'
// import 'jscroll'
import '@fortawesome/fontawesome-free'
import '@fortawesome/fontawesome-free-solid'

const images = require.context('../images/', true);
// const imagePath = name => images(name, true);

window.onload = () => {
  // Menu
  let isActive = false;
  const bugerButton = document.querySelector('a.navbar-burger');
  const modal = document.querySelector('.modal');
  const html = document.querySelector('html');
  const body = document.body;
  bugerButton.addEventListener('click', function (e) {
    if (isActive) {
      this.classList.remove("is-active");
      modal.classList.remove("is-active");

      isActive = false;

      html.style.overflow = 'auto';
      body.style.overflow = 'auto';
      document.removeEventListener('touchmove', cancelTouchmove(e), { passive: false });
    } else {
      this.classList.add("is-active");
      modal.classList.add("is-active");

      isActive = true;

      html.style.overflow = 'hidden';
      body.style.overflow = 'hidden';
      document.addEventListener('touchmove', cancelTouchmove(e), { passive: false });
    }
  });

	// Contact.genre
	const contactGenre = document.getElementById('contact_genre');
	if (contactGenre) {
		const cautionText = document.querySelector('.contact-genre .help');
		contactGenre.onchange = (e) => {
			const selectedValue = e.target.value;
			if (selectedValue == "order") {
				cautionText.textContent = "※個人の方のご注文は受け付けておりません。";
			} else if (selectedValue == "library") {
				cautionText.textContent = "※書籍の掲載については事前の許諾申請なしにお使いいただけます。それ以外のお問い合わせはこちらからお願いいたします。";
			} else {
				cautionText.textContent = "";
			}
		}
	}

  // Smooth Link
  smoothLink();

  // Carousel
  if (document.querySelector('.glide')) {
    new Glide('.glide', {
      type: 'carousel',
      startAt: 0,
      perView: 5,
      autoplay: 5000,
      breakpoints: {
        768: {
          perView: 2
        }
      }
    }).mount();
  }

  // Modal (Book Tameshiyome)
  // const tameshiyomeButton = document.querySelector('.tameshiyome-button');
  // if (tameshiyomeButton) {
  //   const rootElm = document.documentElement;
  //   const tameshiyomeModal = document.querySelector('.tameshiyome-modal');
  //   const tameshiyomeModalBackground = document.querySelector('.tameshiyome-modal-background');
  //   const tameshiyomeCloseButton = document.querySelector('.tameshiyome-close-button');
  //   tameshiyomeButton.addEventListener('click', (e) => {
  //     tameshiyomeModal.classList.add('is-active');
  //     rootElm.classList.add('is-clipped');
  //   });
  //   tameshiyomeModalBackground.addEventListener('click', (e) => {
  //     closeTameshiyomeModal();
  //   });
  //   tameshiyomeCloseButton.addEventListener('click', (e) => {
  //     closeTameshiyomeModal();
  //   });
  //   document.addEventListener('keydown', (e) => {
  //     if (e.key === 'Escape' || e.code === 'Escape') {
  //       closeTameshiyomeModal();
  //     }
  //   });
  //   const closeTameshiyomeModal = () => {
  //     tameshiyomeModal.classList.remove('is-active');
  //     rootElm.classList.remove('is-clipped');
  //   }
  // }
}

// Infinite Scroll (paging)
// $(function () {
//   $('.jscroll').jscroll({
//     // debug: true,
//     padding: 20,
//     nextSelector: 'a.next-link',
//     contentSelector: '.jscroll-list',
//     loadingHtml: '<div class="scroll-loading"></div>',
//   });
//   if ($('.jscroll').length) {
//     $('body, html').animate({ scrollTop: 0 }, 500);
//   }
// });

function cancelTouchmove(e) {
  e.preventDefault();
}

function smoothLink(headH = 0) {
  const interval = 10;
  const divisor = 8;
  const range = (divisor / 2) + 1;
  const links = document.querySelectorAll('a[href^="#"]');

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('click', function (e) {
      e.preventDefault();
      let toY;
      let nowY = window.pageYOffset;
      const href = e.target.getAttribute('href');
      const target = (href == '#' || href == '') ? 'html' : href;
      const targetElm = document.querySelector(target);
      const targetRect = targetElm.getBoundingClientRect();
      const targetY = targetRect.top + nowY - headH;
      (function doScroll() {
        toY = nowY + Math.round((targetY - nowY) / divisor);
        window.scrollTo(0, toY);
        nowY = toY;
        if (document.body.clientHeight - window.innerHeight < toY) {
          window.scrollTo(0, document.body.clientHeight);
          return;
        }
        if (toY >= targetY + range || toY <= targetY - range) {
          window.setTimeout(doScroll, interval);
        } else {
          window.scrollTo(0, targetY);
        }
      })();
    });
  }
};
